//@ts-ignore
import { parse } from "./parser";
import xmltojsdoc from 'xmltojsdoc';

/*const convertType = (ta: string) => {
  const forNamespace =
    "HNodeManager,HNode,TLut1D,TLut3D,TNode,TNodeClassDescription,TNodeParam,TNodeManager"
      .toLowerCase()
      .split(",");
  const t = ta.toLowerCase();
  if (forNamespace.includes(t)) {
    return `${ta}`;
  } else if (t == "pvoid") {
    return "void*";
  } else if (t == "float") {
    return "number";
  } else if (t == "int") {
    return "number";
  } else if (t == "pchar") {
    return "";
  } else {
    return ta;
  }
};*/
const convertType = (ta: string) => {
  if (ta.startsWith("xg_core::")) {
    ta = ta.replace("xg_core::", "BaseTypes.");
  }
  if (ta == "float" || ta == "int" || ta == "uint32_t") {
    return "number";
  }
  if (ta.endsWith("*")) {
    return "number"; //?
  }
  if (ta == "bool") {
    return "boolean";
  }
  return ta;
};

// const PREFIX = `/* GENERATED don't edit */

// export type TErrorCallback = (method: string, message: string) => void;
// export type HLut3D = unknown;
// export type HNodeClassInfo = unknown;
// export type EImageProcessComplexity = unknown;
// export type HNode = unknown;
// export type HNodeManager = unknown;
// export type XgNodeId = number;
// export type TNodeId = number;
// export type TNodePortId = number;
// export type TNodeParamId = number;
// export type TNodeClassId = number;
// export type HContextClient = unknown;
// export type HContext = unknown;
// export type ENodeParamValueType = unknown;

// export enum ENodeParamType
// {
//   enptNone = 0,	// undefined
//   enptBool,		// boolean (e.g. check box)
//   enptInt,		// integer
//   enptFloat,		// floating point
//   //enptEnum,		// enumeration (e.g. combo box)
//   //enptSet,		// set (e.g. check box list)
//   enptString		// string
// };

// export enum ENodeClassProperty
// {
//   encpNone = 0,
//   encpImageProcessMaxComplexity,
//   encpAllowCreate
// };

// // Image pixel sizes (1,2,3,4 floats per pixel)
// export enum EPixelSize
// {
//   epsFloat1 = 1,
//   epsFloat2 = 2,
//   epsFloat3 = 3,
//   epsFloat4 = 4
// };


// export class XgCoreBase {
//   module: any = null;

//   constructor(module:any) {
//     this.module = module;
//   }
// `;

const PREFIX = `/* GENERATED don't edit */
import * as BaseTypes from "./XgCoreBaseTypes";

export class XgCoreBase {
  module: any = null;

  constructor(module:any) {
    this.module = module;
  }
`;

const POSTFIX = `
}
`;

/*export function createTs(items: Array<any>) {
  const createArg = (arg) => {
    return `${arg.name}: ${convertType(arg.type)}`;
  };
  const createCallArg = (arg) => {
    return `${arg.name}`;
  };
  const createArgs = (args) =>
    args ? args.map((arg) => createArg(arg)).join(", ") : "";

  const createCallArgs = (args) =>
    args ? args.map((arg) => createCallArg(arg)).join(", ") : "";

  const body = items
    .map((f) => {
      if (f.type == "operation") {
        return `
  ${f.name}(${createArgs(f.arguments)}):${convertType(f.returns)} {
    this.module.asm.${f.name}(${createCallArgs(f.arguments)});
  }`;
      } else if (f.type == "const") {
        return `const ${convertType(f.valueType)} ${f.name} = ${f.value};`;
      }
    })
    .join("\n");
  return PREFIX + body + POSTFIX;
}*/

export function generateTypeScript(funcs: Array<any>): string {
  const createArg = (arg) => {
    return `${arg.name}: ${convertType(arg.type)}`;
  };
  const createCallArg = (arg) => {
    return `${arg.name}`;
  };
  const createArgs = (args) =>
    args ? args.map((arg) => createArg(arg)).join(", ") : "";
  const createCallArgs = (args) =>
    args ? args.map((arg) => createCallArg(arg)).join(", ") : "";

  const result =
    PREFIX +
    funcs
      .map((f) => {
        try {
          let res = "";
          if (f.docComments) {
            res += xmltojsdoc
              .convert(f.docComments)
              .split('\n')
              .map(line => line.padStart(line.length + (line.startsWith("/") ? 2 : 1)))
              .join('\n')
            + "\n";
          }
          res += `  ${f.name}(${createArgs(f.parameters)}): ${convertType(
            f.return
          )} {
    ${f.return == "void" ? "" : "return "}this.module._${
            f.name
          }(${createCallArgs(f.parameters)});
  }\n`;
          return res;
        } catch (ee) {
          console.error(f, ee);
          return `  /* can't convert ${JSON.stringify(f)} */`;
        }
      })
      .join("\n") +
    POSTFIX;
  return result;
}

export function translateToTree(cppHeader: string): Array<any> {
  const text = cppHeader.split("// START\n")[1];
  const funcs = parse(text);
  return funcs;
}
