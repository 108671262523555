import { FC, useEffect, useState } from "react";
import { MantineProvider, Paper /*, Text, Tabs */ } from "@mantine/core";
import { HeaderAction, HEADER_HEIGHT } from "./Header";
import useDimensions from "react-cool-dimensions";
import MonacoEditor from "react-monaco-editor";
//import { IconTree, IconBrandJavascript } from "@tabler/icons";
//import { JSONTree } from "react-json-tree";
import { translateToTree, generateTypeScript } from "./translateCpp";

//import { MantineLogo } from '@mantine/ds';

/*const jsonTreeTheme = {
  scheme: "monokai",
  author: "wimer hazenberg (http://www.monokai.nl)",
  base00: "#272822",
  base01: "#383830",
  base02: "#49483e",
  base03: "#75715e",
  base04: "#a59f85",
  base05: "#f8f8f2",
  base06: "#f5f4f1",
  base07: "#f9f8f5",
  base08: "#f92672",
  base09: "#fd971f",
  base0A: "#f4bf75",
  base0B: "#a6e22e",
  base0C: "#a1efe4",
  base0D: "#66d9ef",
  base0E: "#ae81ff",
  base0F: "#cc6633",
};*/

export const App: FC = () => {
  const [cppText, setCppText] = useState("");
  const [tsCode, setTsCode] = useState("");
  //const [parsedTree, setParsedTree] = useState<any[]>([]);
  const [size, setSize] = useState({ width: 100, height: 100 });
  const { observe /*unobserve, width, height, entry*/ } = useDimensions({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      setSize({ width, height });
      unobserve(); // To stop observing the current target element
      observe(); // To re-start observing the current target element
    },
  });
  const loadCpp = async () => {
    if (typeof window !== "undefined") {
      const r1 = await fetch("/in/c_api.h");
      const t1 = await r1.text();
      const tree = translateToTree(t1);
      const tsC = generateTypeScript(tree);
      setCppText(t1);
      //setParsedTree(tree);
      setTsCode(tsC);
    }
  };

  const compile = () => {
    const tree = translateToTree(cppText);
    const tsC = generateTypeScript(tree);
    //setParsedTree(tree);
    setTsCode(tsC);
  };

  useEffect(() => {
    loadCpp();
  }, []);

  const options = {
    colorDecorators: true,
    minimap: {
      enabled: false,
    },
    theme: "monokai",
    //keyMap: 'sublime',
    //mode: 'jsx',
  };
  return (
    <MantineProvider
      theme={{ colorScheme: "light" }}
      withGlobalStyles
      withNormalizeCSS
    >
      <div
        style={{
          backgroundColor: "rgb(248, 249, 250)",
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            height: HEADER_HEIGHT,
            right: 0,
          }}
        >
          <HeaderAction compile={compile} links={[]}></HeaderAction>
        </div>
        <Paper
          ref={observe}
          style={{
            position: "absolute",
            left: 10,
            top: HEADER_HEIGHT + 10,
            bottom: 10,
            padding: 0,
            width: "calc( 50% - 15px )",
            overflow: "hidden",
          }}
          shadow="xs"
          p="md"
        >
          <MonacoEditor
            width={size.width}
            height={size.height}
            language="cpp"
            value={cppText}
            options={options}
            onChange={(value) => {
              setCppText(value);
            }}
          />
        </Paper>
        <Paper
          ref={observe}
          style={{
            position: "absolute",
            right: 10,
            top: HEADER_HEIGHT + 10,
            bottom: 10,
            padding: 0,
            width: "calc( 50% - 15px )",
            overflow: "hidden",
          }}
          shadow="xs"
          p="md"
        >
          <MonacoEditor
            width={size.width}
            height={size.height}
            language="typescript"
            value={tsCode}
            theme="light"
            options={{ ...options, readOnly: true, domReadOnly: true }}
          />
          {/*<Tabs defaultValue="gallery">
            <Tabs.List>
              <Tabs.Tab value="gallery" icon={<IconTree size={14} />}>
                Parsed Tree
              </Tabs.Tab>
              <Tabs.Tab
                value="typescript"
                icon={<IconBrandJavascript size={14} />}
              >
                TypeScript
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="gallery" pt="xs">
              <div style={{ overflow: "scroll" }}>
                <JSONTree theme={jsonTreeTheme} data={parsedTree} />
              </div>
            </Tabs.Panel>

            <Tabs.Panel
              value="typescript"
              pt="xs"
              style={{ overflowY: "scroll" }}
            >
            </Tabs.Panel>
        </Tabs>*/}
        </Paper>
      </div>
    </MantineProvider>
  );
};
